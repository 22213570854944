import React from 'react'
import Container from '../components/Container'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import {css} from '@emotion/core'
import {useTheme} from '../components/Theming'
import Img from 'gatsby-image'


const Konzert = ({ data }) => {
  const theme=useTheme()
   
    return(
        <Layout site={data.site} >
         
        
     
        <Container>
          
          <h1 css={css`
            font-size: ${theme.fontsizes.middle};
            @media(max-width: 500px){
              font-size: ${theme.fontsizes.middle};
           
            }
            
          `}>
       Heiteres für Gitarre und Klavier von Anton Diabelli
     </h1>
     <span>Sonntag, den 16. August ab 15 Uhr, Markt 1<br/>Offene Probe mit Benjamin Reiter (Piano) &amp; Jürgen Zimmer (Gitarre)</span><br/><br/>
     <Img 
        fluid={data.bild.childImageSharp.fluid}
        objectFit="cover"
        objectPosition="50% 50%"

        alt="Orion"
        />
     
    
    <div><span style={{fontStyle:'italic'}}><a href="/wiki/Datei:Anton_Diabelli.jpg" class="internal" title="vergrößern und Informationen zum Bild anzeigen"> Anton Diabelli, Lithographie von <a href="/wiki/Josef_Kriehuber" title="Josef Kriehuber">Josef Kriehuber</a>, 1841</a>
    </span>
    </div>
    <br/>
   Markt 1 freut sich über das spontane Angebot von <span style={{textDecoration: 'underline'}}>Benjamin Reiter</span> und seinen Musikerkollegen <span style={{textDecoration: 'underline'}}>Jürgen Zimmer</span>, am Sonntagnachmittag vor unseren Galerieräumen eine "offene Probe" ihres Könnens zu geben. Hierzu für Interessierte weitere Informationen zu dem Komponisten und den beiden Musikern:

<p>Der Name DIABELLI ist den meisten Menschen nur durch die berühmten <span style={{textDecoration:`underline`}}>33 Veränderungen über einen Walzer von A. Diabelli</span> aus der Feder Ludwig van Beethovens bekannt. </p>

<p>Tatsächlich sind aber fast nur Kenner mit dem eigentlichen Oeuvre Anton Diabellis vertraut, und so reiht der Name sich ein in eine Liste diverser heute unbekannterer Komponisten vor allem aus der Wiener Klassik, die zu Lebzeiten mitunter berühmter waren als diejenigen, die uns heute als die „großen Meister“ geläufig sind. </p>
<p>
Auch Diabelli, der ein Schüler Michael und Joseph Haydns war, genoß zu Lebzeiten einen guten Ruf und 
ein nicht unbeträchtliches Ansehen. Dieses hatte er sich vor allem als Klavier- und Gitarrenlehrer erarbeitet - später kommt
     noch seine Tätigkeit als Musikverleger hinzu -, und so liegt ein umfangreiches, vor allem 
     pädagogisch orientiertes Kompositionswerk vor.</p>
<p> Diabelli schuf vielfach klassische Salon- und Hausmusik, oft noch am eigentlich schon ausgeklungenen 
„galanten Stil“ orientiert, obwohl er, wie Beethoven, längst auf der Türschwelle zur Romantik stand. </p>
<p>Er verbleibt einer der wenigen Komponisten gerade aus dieser Zeit, der für die Kombination aus Klavier und Gitarre
     geschrieben hat, und verdient sicherlich allein schon deswegen mehr Beachtung.
Benjamin Reiter (Klavier) und sein langjähriger Kollege Jürgen Zimmer (Gitarre) präsentieren einen unterhaltsamen Querschnitt verschiedener Werke, die sich - mal kurzweilig, mal dramatisch - auf 
vergnügliche Weise in den Gehörgang schmeicheln. </p>
<p>Mit einem gesunden Augenzwinkern agieren die beiden Musiker dabei vertraut, stilecht und mit Schmunzelgarant für den Zuhörer.

     </p>
  
      
     <Img 
        fluid={data.bild2.childImageSharp.fluid}
        objectFit="cover"
        objectPosition="50% 50%"

        alt="Orion"
        />
     

    <br/>
   
     <br/> 
     <p>Der in Oberdiebach beheimatete <span style={{textDecoration: 'underline'}}>Jürgen Zimmer</span> studierte Gitarre und Kammermusik bei Thomas Bittermann und
          Prof. Michael Teuchert an Dr. Hoch’s Konservatorium und der Hochschule für Musik und Darstellende Kunst Frankfurt.</p> 
          <p>Er unterrichtet klassische Gitarre, E-Gitarre, Bass, Liedbegleitung und Bandcoaching in Pop und Rock an der Musikschule Königstein/Taunus.</p>

<p><span style={{fontStyle:'italic'}}>Benjamin Reiter</span> wuchs in Bacharach auf und wurde 
von der ukrainischen Pianistin Tamara Schmedro ausgebildet, bevor er ein Lehramtsstudium 
Musik mit Schwerpunkt Klavier bei Prof. Eike Wernhard an der Hochschule für Musik Frankfurt absolvierte. </p>
<Img 
        fluid={data.bild3.childImageSharp.fluid}
        objectFit="cover"
        objectPosition="50% 50%"

        alt="Orion"
        />
<p>
    <br/>
Er setzte ebendort seine Ausbildung mit dem Diplomstudiengang in Instrumentalpädagogik Klavier bei Prof. Hiroko Maruko fort, 
parallel unterrichtete er an der Musikschule Königstein/Taunus. <br/>
Es folgte der künstlerische Master bei Prof. Jasmin Arakawa, wofür Herr Reiter 
in 2017 ein Stipendium von der University of Florida erhielt, verbunden mit
 einem Lehrauftrag für Klavier Nebenfach. Benjamin Reiter engagiert sich regelmäßig für die musikkulturelle Bereicherung seiner Heimat. </p>
 <p>In 2019 wurde er zum Leiter der Kreismusikschule Donnersbergkreis in Kirchheimbolanden berufen, 
 im August 2020 beginnt seine Lehrtätigkeit an der Wiesbadener Musik- und Kunstschule.</p>

     
    
        </Container>
        </Layout>
     
    )
}




export default Konzert

export const konzertQuery = graphql`

query {
    site {
      ...site
      siteMetadata {
        title
      }
    }
        bild: file(relativePath: { eq: "diabelli.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
    
        bild2: file(relativePath: { eq: "zimmer.jpg" }) {
            childImageSharp {
            
              fluid(maxWidth: 1100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          bild3: file(relativePath: { eq: "reiter.jpg" }) {
            childImageSharp {
            
              fluid(maxWidth: 1100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
  
}

`


